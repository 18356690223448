import React from "react";

// Images
import logo from "../../../images/logo.png";
import frame from "../../../images/frame.png";
import h_frame from "../../../images/horizontal_frame.png";
import nft from "../../../images/nft.png";
import crown from "../../../images/crown.png";
import avax_logo from "../../../images/avax_logo.png";
import roadmap from "../../../images/roadmap.png";

// Icons
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";

// Custom Components
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Arrow from "../components/Arrow";

const Home = () => {
  const [amount, setAmount] = React.useState(0);
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const onClickMint = () => {};

  const advantages = [
    "%20 discounted whitelist privilage for every future NFT collection that we will release.",
    "One week early access for the start of the game and +%200 earning bonus of $NWD for two weeks.",
    "+%200 earning bonus of $NWD for one week at the start of each chapter.",
    "Sürekli olarak düzenlenecek “Bonus Days” +%5 / +%7 / +%12 increase at in-game rewards.",
    "%10 discount while buying in-game materials.",
    "+%2 bonus for every skill they unlock and %10 discount at skill development expenses.",
    "+%5 bonus ROI in banking system",
    "+%10 bonus while earning crafting materials and %10 discount at crafting prices.",
    "+%2 ability bonus for every item that has been crafted.",
    "Ability to create, sell, rent villages and earning comissions from other people using it. Only available to founders.",
    "Ability to create, sell, rent, marketplaces and earning comissions from other people using it. Only available to founders.",
    "+%10 bonus in co-op earnings to both founder and their team.",
    "+%10 bonus in Arena, League and Tournaments rewards and %10 discount at joining fees.",
    "%10 discount at all clan, siege, battle expenses.",
    "+% 5 bonus in treasure rewards.",
    "Right to vote at DAO and in-game decisions.",
  ];

  const faqs = [
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
    {
      question: "When can I mint an NFT ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
    },
  ];

  return (
    <div className="bg-black w-full flex flex-col text-white">
      <img src={logo} alt="logo" className="w-96 mx-auto my-8" />
      <p
        className="merienda text-center p-4 mb-3 text-xl"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, #510B0B 0%, rgba(5, 15, 63, 0) 100%)",
        }}
      >
        CHAPTER I: AWAKENING OF THE FOUNDERS
      </p>
      <p className="montressat text-center lg:w-7/12 w-11/12 mx-auto text-md uppercase">
        For countless centuries they were fighting. In a isolated valley, far
        far away from the mankind. But now the inevitable war between them is
        about to shake the world. Do you hear them coming?
      </p>
      <div className="h-1.5 w-80 text-red-700 mx-auto bg-[#530B0B] my-2.5" />
      <p className="montressat text-center lg:w-7/12 w-11/12 mx-auto uppercase text-md">
        60 drawings. 10.000 NFTs. With the worlds first inflation protected
        economic structure and innumerable in-game features that other P2E games
        cant offer, vampires vs werewolves themed P2E game “New Dawn” is here.
        Hurry up and take your place in this magical world before its too late !
      </p>
      <div className="bg-[#530B0B] mx-auto flex justify-center my-5 py-2 px-32 rounded-md cursor-pointer">
        <p className="merriweather">Mint NOW!</p>
      </div>
      <div className="flex w-8/12 mx-auto text-white flex-col md:flex-row">
        <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
          <p
            className="montserrat -mb-5"
            style={{ fontWeight: "900", fontSize: "25px" }}
          >
            MINTING
          </p>
          <div
            style={{
              backgroundImage: `url(${frame})`,
              backgroundSize: "cover",
            }}
          >
            <img
              src={nft}
              alt="nft"
              className="w-full pt-7 px-10 md:px-7 pb-6"
            />
            <div className="flex justify-center space-x-6">
              <IoMdRemoveCircleOutline
                className="my-auto cursor-pointer"
                size={20}
                onClick={() => setAmount(amount - 1)}
              />
              <p className="merriweather text-white text-xl">{amount}</p>
              <IoIosAddCircleOutline
                className="my-auto cursor-pointer"
                size={20}
                onClick={() => setAmount(amount + 1)}
              />
            </div>
            <div
              className="bg-[#530B0B] mx-auto flex justify-center mt-2 py-1 w-5/12 rounded-md cursor-pointer"
              onClick={onClickMint}
            >
              <p className="text-xl" style={{ fontWeight: "900" }}>
                Mint
              </p>
            </div>
            <div className="mx-auto flex justify-center py-1 w-5/12 rounded-md cursor-pointer">
              <p className="text-xl my-auto" style={{ fontWeight: "900" }}>
                1
              </p>
              <img
                src={avax_logo}
                alt="avax_logo"
                className="w-6 h-6 ml-2 my-auto"
              />
            </div>
            <p
              className="text-md text-center mb-6"
              style={{ fontWeight: "900" }}
            >
              0 / 10.000
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2  flex flex-col justify-center items-center md:border-l-4 md:border-l-[#530B0B] px-4">
          <p
            className="text-center mt-5 montressat text-2xl"
            style={{ fontWeight: 900 }}
          >
            A BRIEF INTRODUCTION TO NEW DAWN
          </p>
          <img src={h_frame} alt="h_frame" className="w-full mt-5" />
          <p className="text-center mt-5 montressat text-md">
            For detailed information and to have a better understanding of the
            game mechanics, please check our{" "}
            <a href="/" target="_blank" className=" underline">
              whitepaper
            </a>
            .
          </p>
        </div>
      </div>
      <div className="my-10 flex flex-col">
        <div className="flex justify-center space-x-10">
          <img src={crown} alt="crown" className="w-10 h-10 my-auto" />
          <p
            className="text-center montressat text-md my-auto lg:text-4xl"
            style={{ fontWeight: 900 }}
          >
            Founder Advantages
          </p>
          <img src={crown} alt="crown" className="w-10 h-10 my-auto" />
        </div>
        <p className="montressat text-center text-xs w-8/12 mx-auto mt-7 font-light lg:w-5/12 lg:text-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
        </p>
        <ul className="text-white mx-auto italic space-y-2 list-disc mt-8 md:w-6/12 w-8/12">
          {advantages.map((advantage, index) => (
            <li className="montressat text-sm mx-auto font-light md:text-md">
              {advantage}
            </li>
          ))}
        </ul>
        <p className="montressat text-sm font-light my-6 text-center w-8/12 lg:5/12 mx-auto lg:text-md">
          Check out our discord channel or whitepaper to get more information
          about how to be a founder.
        </p>
      </div>
      <div className="my-6 flex flex-col">
        <p
          className="text-center montressat text-4xl my-auto"
          style={{ fontWeight: 900 }}
        >
          ROADMAP
        </p>
      </div>
      <div className="my-6 flex flex-col">
        <p
          className="text-center montressat text-4xl my-auto"
          style={{ fontWeight: 900 }}
        >
          FAQ
        </p>

        <div className="my-10 w-8/12 mx-auto space-y-2">
          {faqs.map((faq, idx) => (
            <Accordion
              open={open === idx + 1}
              icon={<Arrow id={idx + 1} open={open} />}
              className="bg-[#6A1313] rounded-lg px-10"
            >
              <AccordionHeader
                onClick={() => handleOpen(idx + 1)}
                className="border-0 montressat text-white hover:text-white py-2.5 text-sm lg:text-lg"
                style={{ fontWeight: 900 }}
              >
                {faq.question}
              </AccordionHeader>
              <AccordionBody className="montressat text-white pb-7 lg:text-lg text-xs overflow-auto">
                {faq.answer}
              </AccordionBody>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
