import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Home from "./components/ui/pages/Home";

// Components
import Header from "./components/ui/components/Header";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
