import React from "react";

// History
import { useHistory } from "react-router-dom";

// Hooks
import { useAccount } from "../../hooks/index";
import { useWeb3 } from "../../providers/index";

import { GiHamburgerMenu, GiCancel } from "react-icons/gi";

const Header = () => {
  const history = useHistory();

  const { account } = useAccount();
  const { connect } = useWeb3();

  const [isNavOpen, setIsNavOpen] = React.useState(false);

  return (
    <>
      <div className="py-3 lg:flex w-full bg-black px-32 justify-around merriweather text-white test-sm hidden">
        <p className="my-auto cursor-pointer" onClick={() => history.push("/")}>
          Minting
        </p>
        <p className="my-auto cursor-pointer">Play</p>
        <p className="my-auto cursor-pointer">My NFTs</p>
        <p className="my-auto cursor-pointer">Market</p>
        <p className="my-auto cursor-pointer">Docs</p>
        <div
          className="flex bg-white text-black p-1.5 my-auto text-sm rounded-sm cursor-pointer"
          onClick={() => (account && account?.data ? () => {} : connect())}
        >
          {account && account?.data ? (
            <>{account?.data.slice(0, 15)}</>
          ) : (
            "Connect Wallet"
          )}
        </div>
      </div>
      <section className="MOBILE-MENU flex justify-end lg:hidden bg-black">
        <GiHamburgerMenu
          className="text-white mt-3 mr-3 cursor-pointer"
          size={30}
          onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
        />

        <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
          <div
            className="absolute top-0 right-0 px-8 py-8"
            onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
          >
            <GiCancel className="text-white text-2xl" />
          </div>

          <ul className="montressat font-bold flex flex-col items-center justify-between min-h-[250px] text-white">
            <li className="my-8 uppercase">
              <a href="/">Minting</a>
            </li>
            <li className="my-8 uppercase">
              <a href="/">My NFTs</a>
            </li>
            <li className="my-8 uppercase">
              <a href="/">Market</a>
            </li>
            <li className="my-8 uppercase">
              <a href="/">Docs</a>
            </li>
          </ul>
        </div>
      </section>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: black;
      }
    `}</style>
    </>
  );
};

export default Header;
